import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from "react-notifications";

// icon
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { observer } from "mobx-react-lite";
import i18next from "i18next";

import icon11 from "assets/img/icon/icon11.png";
import icon12 from "assets/img/icon/icon12.png";
import icon13 from "assets/img/icon/icon13.png";
import icon14 from "assets/img/icon/icon14.png";
import icon15 from "assets/img/icon/icon15.png";
import icon16 from "assets/img/icon/icon16.png";
import icon17 from "assets/img/icon/icon17.png";
import icon18 from "assets/img/icon/icon18.png";
import icon19 from "assets/img/icon/icon19.png";
import btc from "assets/img/icon/btc.png";

const MemberPage = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, link_download, logo, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage =
        i18next.t(error?.message) ?? i18next.t("action_failed_msg");
      NotificationManager.error(errorMessage);
    },
  });

  const onGetProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  return (
    <div className="member-page">
      <div className="member-header">
        <img src={logo} alt="" className="logo" />
        <img src={user?.avatar_url} className="avatar" alt="" />
        <span className="username">{user?.full_name_display}</span>
        <span className="ref">ID: {user?.id_display}</span>
        <FontAwesomeIcon
          className="btn-setting"
          icon={faCog}
          onClick={() => navigate("/board/member/info")}
        />
      </div>
      <div className="container_main">
        <ul className="memberMenu">
          <li>
            <div
              className="link"
              onClick={() => {
                if (!user?.is_verify) {
                  navigate("/board/member/kyc/first");
                }
              }}
            >
              <div>
                <img src={icon11} alt="" />
                <span>{i18next.t("primary_verification")}</span>
              </div>
              <div>
                {!user?.is_verify && <span>{i18next.t("un_verify")}</span>}
                {user?.is_verify === 1 && (
                  <span>{i18next.t("verification_progress")}</span>
                )}
                {user?.is_verify >= 2 && (
                  <span className="text-green">{i18next.t("verified")}</span>
                )}
                <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
              </div>
            </div>
          </li>

          <li>
            <div
              className="link"
              onClick={() => {
                if (user?.is_verify === 2) {
                  navigate("/board/member/kyc");
                } else {
                  NotificationManager.info(
                    "Vui lòng hoàn thành xác mình sơ cấp"
                  );
                }
              }}
            >
              <div>
                <img src={icon12} alt="" />
                <span>{i18next.t("premium_verification")}</span>
              </div>
              <div>
                {user?.is_verify <= 2 && <span>{i18next.t("un_verify")}</span>}
                {user?.is_verify === 3 && (
                  <span>{i18next.t("verification_progress")}</span>
                )}
                {user?.is_verify === 4 && (
                  <span className="text-green">{i18next.t("verified")}</span>
                )}
                {user?.is_verify >= 5 && (
                  <span className="text-green">{i18next.t("verified")}</span>
                )}
                <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
              </div>
            </div>
          </li>
          <li>
            <Link to="/board/history">
              <div>
                <img src={icon13} alt="" />
                <span>{i18next.t("transaction_history")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <Link to="/board/trade/history">
              <div>
                <img src={icon14} alt="" />
                <span>{i18next.t("quick_location")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <Link to="/board/feature/history">
              <div>
                <img src={icon14} alt="" />
                <span>{i18next.t("contract_position")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>

          <li>
            <Link to="/board/member/wallet">
              <div>
                <img src={btc} alt="" />
                <span>{i18next.t("wallet_address")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <Link to="/board/member/bank">
              <div>
                <img src={icon15} alt="" />
                <span>{i18next.t("bank_account_2")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <Link to="/board/member/sharing">
              <div>
                <img src={icon16} alt="" />
                <span>{i18next.t("i_want_to_share")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <Link to="/cskh">
              <div>
                <img src={icon19} alt="" />
                <span>{i18next.t("online_customer_care")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <Link to="/board/member/introduction">
              <div>
                <img src={icon17} alt="" />
                <span>{i18next.t("support_center")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <Link to="/download">
              <div>
                <img src={icon18} alt="" />
                <span>{i18next.t("introducing_platform")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
});

export default MemberPage;
