import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import i18next from "i18next";
import { formatNumber } from "_common/utils/formatValue";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import classNames from "classnames";

const Usdt = observer(({ onClose }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, top_coin, games },
  } = useStores();

  const [gameList, setGameList] = useState(games);

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const coinListPrice = useMemo(
    () =>
      gameList.map((item) => {
        const price = data?.find(
          (obj) => obj?.symbol?.toLowerCase() === item?.code
        );

        return { ...item, price };
      }),
    [gameList, data]
  );

  const handleKeydown = (event) => {
    if (event?.keyCode === 13) {
      const valueKey = event?.target?.value;
      const cloneData = [...gameList];
      const result = cloneData.filter(
        (obj) => obj?.name?.toLowerCase()?.search(valueKey?.toLowerCase()) > -1
      );
      if (!valueKey) {
        setGameList(games);
      } else {
        setGameList(result);
      }
    }
  };

  return (
    <div className="coin-list">
      <div className="search-container">
        <FontAwesomeIcon icon={faSearch} />
        <input
          onKeyDown={handleKeydown}
          type="text"
          placeholder={i18next.t("currency_search")}
        />
      </div>
      <div className="list">
        {coinListPrice?.map((item) => (
          <div
            onClick={() => {
              navigate(`/board/trade?symbol=${item?.code}`);
              onClose();
            }}
            className="item"
            key={item?.id}
          >
            <div className="col1">
              <div>
                <p className="name">
                  <b>{item?.name?.split("/")?.[0]}</b>
                  <span>/{item?.name?.split("/")?.[1]}</span>
                </p>
              </div>
            </div>
            <div className="col2">
              <span
                className={classNames({
                  "text-green": item?.price?.priceChangePercent > 0,
                  "text-red": item?.price?.priceChangePercent <= 0,
                })}
              >
                {formatNumber(item?.price?.lastPrice, "0.[00000]")}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default Usdt;
