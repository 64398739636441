import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";

import Pagination from "_common/component/Pagination";
import { PaymentApi } from "states/api/payment";
import {
  paymentMethodText,
  paymentStatusText,
  paymentStatusColor,
  paymentTypeText,
  paymentTypeColor,
  paymentAddType,
} from "_common/constants/statusType";
import moment from "moment";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import ListData from "./ListData";

export default function ListHistoryBalance() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  const [params] = useSearchParams();
  const type = params.get("type");

  const { data, refetch } = useQuery(
    ["payment", "list", type],
    () =>
      PaymentApi.getList({
        params: {
          page: 1,
          limit: 30,
          type,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="container  px-4 mx-auto mt-10">
      <HeaderTop title={i18next.t("transaction_history")} />
      <div className="flex flex-col mt-6">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full px-4 py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden rounded-lg shadow">
              <ListData data={data} />
            </div>
          </div>
        </div>
      </div>
      {/* <Pagination /> */}
    </div>
  );
}
