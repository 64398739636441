import i18next from "i18next";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";

// redux

import LoginForm from "./LoginForm";

function LoginPage() {
  const navigate = useNavigate();
  const {
    authStore: { banner_login_top, banner_login_footer, logo },
  } = useStores();

  return (
    <div className="sbui-sidepanel-container">
      <div>
        <div className="sbui-sidepanel--medium auth-page">
          <div className="sbui-sidepanel  py-10">
            <div
              className="sbui-space-col sbui-space-y-6"
              style={{ height: "100%" }}
            >
              <div className="sbui-sidepanel-content auth-container">
                <div className="flex flex-col  h-full">
                  <div className="sbui-auth">
                    <div className="sbui-space-col sbui-space-y-8 sbui-auth-label">
                      {banner_login_top && (
                        <img
                          id="banner_login_top"
                          src={banner_login_top}
                          alt=""
                        />
                      )}
                      <img id="logo" src={logo} alt="" />
                      <LoginForm />
                      {banner_login_footer && (
                        <img
                          id="banner_login_footer"
                          src={banner_login_footer}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
