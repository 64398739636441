import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

export default function Complain() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { token, general },
  } = useStores();

  return (
    <div className="container  px-4 mx-auto mt-10">
      <HeaderTop title={i18next.t("support")} />
      <iframe
        src={`${general?.link_support}?token=${token}`}
        title="CSKH"
        key="cskh"
        className="cskh-iframe"
        width="100%"
        height="100%"
      />
    </div>
  );
}
