import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { FundApi } from "states/api/fund";
import logo from "assets/img/logo.png";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate, useParams } from "react-router-dom";
import { useStores } from "_common/hooks";
import { get } from "lodash";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { UserFundApi } from "states/api/userFund";
import { NotificationManager } from "react-notifications";

const schema = yup
  .object({
    amount: yup
      .number()
      .min(0, `${i18next.t("enter_large_amount_msg")} 0`)
      .required(i18next.t("verify_require")),
  })
  .required();

export default function FundDetail() {
  const navgate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams();
  const id = params?.id;
  const {
    authStore: { user, user_wallet, display_balance },
  } = useStores();
  const [amount, setAmount] = useState(0);

  const { data: detail, refetch } = useQuery(
    ["funds", "detail", id],
    () =>
      FundApi.getDetail({
        id,
      }),
    {
      staleTime: 300000,
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    refetch();
    setValue("amount", 0);
  }, []);

  const { mutate, isLoading } = useMutation(
    (variables) => UserFundApi.create({ params: variables }),
    {
      onSuccess: (res) => {
        navgate(-1);
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    trigger();
    const amount = values?.amount;

    if (Number(amount) > Number(detail?.max)) {
      return NotificationManager.error(
        `${i18next.t("enter_smaller_deposit_amount")} ${formatNumber(
          detail?.max
        )}`
      );
    }

    if (Number(amount) < Number(detail?.min)) {
      return NotificationManager.error(
        `${i18next.t("enter_large_deposit_amount")} ${formatNumber(
          detail?.min
        )}`
      );
    }

    mutate({
      ...values,
      fund_id: id,
    });

    return null;
  };

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  const profitPlan = useMemo(() => {
    const min = Number(get(detail, "min", 0));
    const max = Number(get(detail, "max", 0));
    const date = Number(get(detail, "date", 0));
    const max_profit = Number(get(detail, "max_profit", 0)) / 100;
    const min_profit = Number(get(detail, "min_profit", 0)) / 100;
    return {
      min_fund: min * min_profit * date,
      max_fund: max * max_profit * date,
    };
  }, [detail]);

  return (
    <div className="sbui-loading">
      <HeaderTop title={i18next.t("product_details")} />
      <form className="fund-detail" onSubmit={handleSubmit(onSave)}>
        <div key={detail?.id} className="item">
          <div className="fund-head flex  items-center justify-between gap-2">
            <div className="flex items-center gap-4">
              <img src={logo} alt="" />
              <div>
                <p className="text-sm">{i18next.t("receive")} USDT</p>
                <p className="text-md font-medium">{detail?.name}</p>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <h4 className="text-sm text-right">{i18next.t("latest_date")}</h4>
              <span className="text-sm  text-yellow-500">
                {formatNumber(detail?.min_profit, "0,0.[000]")}% ~{" "}
                {formatNumber(detail?.max_profit, "0,0.[000]")}%
              </span>
            </div>
          </div>
          <div className="card-header flex  justify-between items-center gap-2 mt-5">
            <div className="flex flex-col items-center">
              <h3 className="text-xl  font-bold text-center ">
                {formatNumber(detail?.date)}(Ngày)
              </h3>
              <p className="text-sm text-center">
                {i18next.t("asset_management_cycle")}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <h3 className="text-xl font-bold  text-center ">
                {formatNumber(detail?.min, "0")} ~{" "}
                {formatNumber(detail?.max, "0")}
              </h3>
              <p className="text-sm text-center">
                {i18next.t("limit_per_transaction")} (USDT)
              </p>
            </div>
          </div>
          <div className="section-info section-1 flex  justify-between items-center gap-4">
            <div className="flex flex-col items-center w-full">
              <p className="text-sm text-center">{i18next.t("earning_time")}</p>
              <h3 className="text-md  font-bold text-center text-yellow-500">
                {i18next.t("everyday")}
              </h3>
            </div>
            <div className=" flex flex-col items-center w-full">
              <p className="text-sm text-center">{i18next.t("Quỹ uỷ thác")}</p>
              <h3 className="text-md  font-bold text-center text-yellow-500">
                {i18next.t("return_at_maturity")}
              </h3>
            </div>
            <div className="flex flex-col items-center w-full">
              <p className="text-sm text-center">{i18next.t("redeem_first")}</p>
              <h3 className="text-md  font-bold text-center text-yellow-500">
                {formatNumber(detail?.profit_refund, "0,0.[000]")}%
              </h3>
            </div>
          </div>
          <div className="section-info flex  justify-between items-center gap-4 mt-2">
            <div className="flex flex-col items-center w-full">
              <p className="text-sm text-center">
                {i18next.t("estimated_income")} (USDT)
              </p>
              <h3 className="text-md  font-bold text-center text-yellow-500">
                {formatNumber(profitPlan?.min_fund, "0,0.00")} ~
                {formatNumber(profitPlan?.max_fund, "0,0.00")}
              </h3>
            </div>
            <div className="flex flex-col items-center w-full">
              <p className="text-sm text-center">
                {i18next.t("available_assets")} (USDT)
              </p>
              <h3 className="text-md  font-bold text-center text-yellow-500">
                {formatNumber(usdtBalance?.amount, "0,0.[000]")}
              </h3>
            </div>
          </div>
          <div className="section-info  mt-2">
            <p>{i18next.t("investment_amount")}</p>
            <div className="sbui-formlayout__content-container-horizontal mt-2">
              <div className="sbui-input-container input-fund">
                <input
                  type="number"
                  inputMode="decimal"
                  min={0}
                  max={1000000000000}
                  step={0.000001}
                  className="sbui-input sbui-input--medium"
                  placeholder={i18next.t("investment_amount_msg")}
                  onKeyUp={(e) => setAmount(e?.target?.value)}
                  {...register("amount")}
                />
                <span className="text-sm text-uppercase">USDT</span>
                <button
                  type="button"
                  className="btn btn-all"
                  onClick={() => {
                    const temp = Number(get(usdtBalance, "amount", 0));
                    setAmount(temp);
                    setValue("amount", temp);
                  }}
                >
                  {i18next.t("total")}
                </button>
              </div>
            </div>
          </div>
          <div className="section-info mt-2">
            <div>
              <p className="text-sm">{i18next.t("fund_detail_msg")}</p>
            </div>
          </div>
          <button type="submit" className="btn-fund">
            {i18next.t("purchase_confirmation")}
          </button>
        </div>
      </form>
    </div>
  );
}
