import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "@tanstack/react-query";
import { FundApi } from "states/api/fund";
import { useStores } from "_common/hooks";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

import { useNavigate } from "react-router-dom";

import ListData from "./ListData";

const FundList = observer(() => {
  const naivagte = useNavigate();
  const {
    authStore: { user, user_wallet, display_balance },
  } = useStores();

  const { data, refetch } = useQuery(
    ["funds", "list"],
    () =>
      FundApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="container fund-detail-page my-6">
      <HeaderTop title={i18next.t("financial_management_products")} />
      <div className=" mt-5">
        <div className="sbui-card-content header-card">
          <div className="flex justify-between w-full head-pro-title">
            <div className="flex items-center justify-center gap-2">
              <div className="flex flex-col">
                <h4>{i18next.t("fund_entrusted")}</h4>
                <span className="text-3xl font-bold">{user?.fund?.total}</span>
              </div>

              <div className="flex items-center justify-center gap-2">
                <span className="sbui-btn-container">
                  <button
                    type="button"
                    onClick={() => naivagte("/board/fund/history")}
                    className="sbui-btn btn-history"
                  >
                    <span>{i18next.t("entrustment_order")}</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div className="flex  justify-between items-center gap-2 info-card ">
            <div className="flex flex-col items-center">
              <span className="text-xl font-bold">
                {formatNumber(user?.fund?.profit_today)}
              </span>
              <h4 className="text-sm">
                {i18next.t("estimated_earnings_today")}
              </h4>
            </div>
            <div className="flex flex-col items-center">
              <span className="text-xl font-bold ">
                {formatNumber(user?.fund?.total)}
              </span>
              <h4 className="text-sm">{i18next.t("cumulative_income")}</h4>
            </div>
            <div className="flex flex-col items-center">
              <span className="text-xl font-bold">
                {formatNumber(user?.fund?.count)}
              </span>
              <h4 className="text-sm">{i18next.t("order_entrusting")}</h4>
            </div>
          </div>
        </div>
        <ListData data={data?.data || []} />
      </div>
    </div>
  );
});

export default FundList;
