import React, { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next, { use } from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { PriceApi } from "states/api/price";

import icon21 from "assets/img/icon/icon21.png";
import { get } from "lodash";

const schema = yup
  .object({
    amount: yup.number().required(i18next.t("verify_require")),
    otp: yup.string(),
  })
  .required();

export default function Exchange() {
  const queryClient = useQueryClient();

  const {
    authStore: { user, general, coins },
  } = useStores();

  const [amount, setAmount] = useState();
  const [token_from, setTokenFrom] = useState(coins[0]?.symbol);
  const [token_to, setTokenTo] = useState(coins[1]?.symbol);

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      token_from,
      token_to,
    },
  });

  const { data: dataPrice, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "token_from") {
        setTokenFrom(value?.token_from);
      }
      if (name === "token_to") {
        setTokenTo(value?.token_to);
      }
      if (name === "amount") {
        setAmount(value?.amount);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const usdtPrice = useMemo(() => {
    const usdt = coins?.find((obj) => obj?.symbol?.toLowerCase() === "usdt");
    return Number(usdt || 1);
  }, []);

  const mainBalance = useMemo(
    () => user?.tokens?.find((obj) => obj?.symbol === token_from),
    [user, token_from]
  );

  const mainToBalance = useMemo(
    () => user?.tokens?.find((obj) => obj?.symbol === token_to),
    [user, token_to]
  );

  const priceCoin = useMemo(() => {
    let lastPrice = 1;
    const tokeFrom = coins?.find(
      (obj) => obj?.symbol?.toLowerCase() === token_from
    );
    const tokenTo = coins?.find(
      (obj) => obj?.symbol?.toLowerCase() === token_to
    );

    const priceFrom = Number(tokeFrom?.price || 1);
    const priceTo = Number(tokenTo?.price || 1);
    if (tokeFrom && tokenTo) {
      if (
        tokeFrom?.network_name !== "spot" &&
        tokenTo?.network_name === "spot"
      ) {
        lastPrice = priceTo * priceFrom;
      } else if (
        tokenTo?.symbol === "eth" ||
        tokenTo?.symbol === "btc" ||
        tokenTo?.symbol === "usd"
      ) {
        lastPrice = priceFrom / priceTo;
      } else {
        lastPrice = priceTo / priceFrom;
      }
    }

    return {
      tokenTo,
      tokeFrom,
      priceFrom,
      priceTo,
      lastPrice,
    };
  }, [token_to, token_from]);

  const amountReceive = useMemo(() => {
    if (!priceCoin?.lastPrice) return 0;
    const fromPrice = Number(priceCoin?.priceFrom);
    const toPrice = Number(priceCoin?.priceTo);

    let convert = amount * (toPrice / fromPrice);
    if (
      priceCoin?.tokeFrom?.network_name !== "spot" &&
      priceCoin?.tokenTo?.network_name === "spot"
    ) {
      convert = amount * (toPrice * fromPrice);
    } else if (
      priceCoin?.tokeFrom?.network_name === "spot" &&
      priceCoin?.tokenTo?.network_name !== "spot"
    ) {
      convert = amount * (1 / toPrice / fromPrice);
    }
    return convert;
  }, [amount, priceCoin]);

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.exchange({
        params: {
          ...variables,
          amount_convert: amountReceive,
          type: "exchange",
        },
      }),
    {
      onSuccess: (res) => {
        reset();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list_exchange"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("withdrawal_successful")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    const amount = Number(values?.amount);
    if (!amount || amount <= 0)
      return NotificationManager.error(
        `${i18next.t("enter_large_amount_msg")} 0`
      );
    if (amount > Number(mainBalance?.amount || 0))
      return NotificationManager.error(
        `${i18next.t("enter_smaller_amount_msg")} ${formatNumber(
          mainBalance?.amount,
          "0.[00000]"
        )}`
      );
    if (values?.token_from === values?.token_to)
      return NotificationManager.error(
        i18next.t("choose_currency_differences_msg")
      );

    onSubmit({
      ...values,
      fromToken: { lastPrice: priceCoin?.lastPrice },
      toToken: { lastPrice: 1 },
    });
    return null;
  };

  return (
    <div className="container mt-5">
      <HeaderTop title={i18next.t("exchange")} />
      <div id="withdraw">
        <div className="flex flex-col w-full gap-10  mt-6">
          <div className="flex flex-1">
            <div className="sbui-card w-full h-full">
              <form
                className="sbui-card-content"
                onSubmit={handleSubmit(onSave)}
              >
                <div className="flex flex-col w-full gap-4">
                  <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                    <div className="sbui-formlayout__content-container-horizontal">
                      <div className="sbui-input-container control-flex">
                        <select
                          className="sbui-select sbui-select--medium"
                          {...register("token_from")}
                        >
                          {coins.map((item) => (
                            <option key={item?.id} value={item?.symbol}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <img
                          src={icon21}
                          alt=""
                          role="presentation"
                          onClick={() => {
                            setTokenFrom(token_to);
                            setTokenTo(token_from);
                          }}
                        />
                        <select
                          className="sbui-select sbui-select--medium"
                          {...register("token_to")}
                        >
                          {coins.map((item) => (
                            <option key={item?.id} value={item?.symbol}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                      <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                        <div className="sbui-formlayout__label">
                          {i18next.t("amount_of_exchange")}
                        </div>
                      </div>
                      <div className="sbui-formlayout__content-container-horizontal">
                        <div className="sbui-input-container input-exchange">
                          <span className="text-2xl font-medium text-uppercase">
                            {token_from}
                          </span>
                          <input
                            type="number"
                            inputMode="decimal"
                            min={0}
                            max={1000000000000000}
                            step={0.000001}
                            className="sbui-input sbui-input--medium"
                            placeholder={i18next.t("amount_of_exchange_msg")}
                            {...register("amount")}
                          />
                          <button
                            type="button"
                            className="btn btn-all"
                            onClick={() => {
                              const temp = Number(
                                get(mainBalance, "amount", 0)
                              );
                              setAmount(temp);
                              setValue("amount", temp);
                            }}
                          >
                            {i18next.t("change_all")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="exchange-info">
                    <div className="block ">
                      <span>{i18next.t("foreign_exchange_rate")}</span>
                      <span>{formatBalance(priceCoin?.lastPrice)}</span>
                    </div>
                    <div className="block ">
                      <span>
                        {" "}
                        {i18next.t("availability")} {mainBalance?.name}
                      </span>
                      <span> {formatBalance(mainBalance?.amount)}</span>
                    </div>
                    <div className="block ">
                      <span>
                        {i18next.t("can_be_received")} {mainToBalance?.name}
                      </span>

                      <span>{formatBalance(amountReceive)}</span>
                    </div>
                    <div className="block ">
                      <span>{i18next.t("transaction_fee_rate")}</span>
                      <span>
                        {formatNumber(general?.exchange_fee, "0,0.[000000]")}
                      </span>
                    </div>
                    <div className="block ">
                      <span>{i18next.t("transaction_fee")}</span>
                      <span>
                        {formatNumber(general?.exchange_fee, "0,0.[000000]")}
                      </span>
                    </div>
                  </div>
                  <span className="sbui-btn-container sbui-btn--w-full">
                    <button
                      type="submit"
                      className="sbui-btn sbui-btn-primary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
                    >
                      <span>{i18next.t("redeem")}</span>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
