import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import i18next from "i18next";

import General from "./General";

const WalletPage = observer(() => {
  const [activeTab, setActiveTab] = useState("general");

  return (
    <div className="mb-10">
      <General />
    </div>
  );
});

export default WalletPage;
