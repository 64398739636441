import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";

import { formatBalance, formatNumber } from "_common/utils/formatValue";
import icon9 from "assets/img/icon/icon9.png";
import icon10 from "assets/img/icon/icon10.png";
import icon8 from "assets/img/icon/icon8.png";
import classNames from "classnames";

export default function General() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { display_balance, user, tokens },
  } = useStores();

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  const [active, setActive] = useState("main");

  const listCoin = useMemo(() => {
    if (active === "coin")
      return tokens?.filter((obj) => obj?.symbol === "btc");

    return tokens;
  }, [active, tokens]);

  return (
    <div id="general">
      <div className="flex flex-col w-full gap-10 ">
        <div className="flex flex-1">
          <div className="flex flex-col w-full gap-4">
            <div className="sbui-card card-header">
              <div className="sbui-card-content">
                <div className="flex items-center justify-center">
                  <div className="flex items-center">
                    <div className="flex flex-col">
                      <span className="flex flex-col ">
                        <span className="text-sm">{i18next.t("asset")}</span>
                        <h1 className="text-3xl font-medium">
                          {formatBalance(usdtBalance?.amount, display_balance)}{" "}
                          USDT
                        </h1>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="quick-action">
                  <Link to="/board/recharge" className="item">
                    <img src={icon9} alt="" />
                    <p>{i18next.t("deposit")}</p>
                  </Link>
                  <Link to="/board/withdraw" className="item">
                    <img src={icon10} alt="" />
                    <p>{i18next.t("withdraw")}</p>
                  </Link>
                  <Link to="/board/exchange" className="item">
                    <img src={icon8} alt="" />
                    <p>{i18next.t("exchange")}</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="tabs tab-head">
              <div
                className={classNames("tab ", {
                  active: active === "main",
                })}
                onClick={() => setActive("main")}
              >
                {i18next.t("asset")} {i18next.t("account")}
              </div>
              <div
                className={classNames("tab ", {
                  active: active === "coin",
                })}
                onClick={() => setActive("coin")}
              >
                {i18next.t("asset")} {i18next.t("coin")}
              </div>
            </div>
            <div className="list-token">
              {listCoin?.map((item) => (
                <div className="sbui-card " key={item?.id}>
                  <div className="sbui-card-content p-2">
                    <span className="text-lg font-medium text-gray-600">
                      {item?.name}
                    </span>
                    <div className="flex items-center justify-between mt-2">
                      <div className="flex items-center">
                        <div className="flex flex-col text-center">
                          <span className="text-sm text-gray-500">
                            {i18next.t("available")}({item?.name})
                          </span>
                          <span className="text-sm text-gray-500">
                            {formatBalance(item?.amount, display_balance)}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col text-center">
                        <span className="text-sm text-gray-500">
                          {i18next.t("amount_pending")}({item?.name})
                        </span>
                        <span className="text-sm text-gray-500">
                          {formatBalance(item?.pending_amount, display_balance)}
                        </span>
                      </div>
                      <div className="flex flex-col text-center">
                        <span className="text-sm text-gray-500">
                          {i18next.t("exchange")}(USDT)
                        </span>
                        <span className="text-sm text-gray-500">
                          {formatBalance(item?.convert_amount, display_balance)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
