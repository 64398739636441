import { observer } from "mobx-react-lite";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import cskh from "assets/img/icon/icon22.png";
import MobileFooter from "../Footer/MobileFooter";

const UnAuthLayout = observer(() => {
  const navigate = useNavigate();

  return (
    <div className="mobile-layout">
      <Outlet />
      <MobileFooter />
      {/* <div className="cskh-icon">
        <img
          role="presentation"
          src={cskh}
          alt=""
          onClick={() => navigate("/cskh")}
        />
      </div> */}
    </div>
  );
});

export default UnAuthLayout;
