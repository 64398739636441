import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi, ProfileApi } from "states/api";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

const schema = yup
  .object({
    new_password: yup
      .string()
      .min(6, "Vui lòng nhập mật khẩu tối thiểu 6 ký tự")
      .required("Vui lòng nhập mật khẩu mới"),
    confirmed: yup
      .string()
      .required("Vui lòng nhập lại mật khẩu mới")
      .oneOf([yup.ref("new_password")], "Mật khẩu không khớp"),
  })
  .required();

export default function Security() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, general, clear },
  } = useStores();

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.changePassword({ params: variables }),
    {
      onSuccess: (res) => {
        clear();
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSave = () => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      mutate(formValue);
    }
  };

  return (
    <div className="container mt-10">
      <HeaderTop title={i18next.t("edit_login_password")} />
      <div className="sbui-card">
        <form className="sbui-card-content" onSubmit={handleSubmit(onSave)}>
          <div className="flex flex-col items-center justify-between gap-4 mb-5 ">
            <div className="flex flex-col w-full">
              <div>
                <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
                  <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                    <div className="sbui-formlayout__label">
                      {i18next.t("old_password")}
                    </div>
                  </div>
                  <div className="sbui-formlayout__content-container-horizontal">
                    <div className="sbui-input-container">
                      <input
                        name="password"
                        placeholder={i18next.t("old_password")}
                        type="password"
                        className="sbui-input sbui-input--large"
                        defaultValue=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div>
                <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
                  <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                    <div className="sbui-formlayout__label">
                      {i18next.t("password")}
                    </div>
                  </div>
                  <div className="sbui-formlayout__content-container-horizontal">
                    <div className="sbui-input-container">
                      <input
                        name="password"
                        placeholder={i18next.t("password")}
                        type="password"
                        className="sbui-input sbui-input--large"
                        defaultValue=""
                        {...register("new_password")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div>
                <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
                  <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                    <div className="sbui-formlayout__label">
                      {i18next.t("confirmed_password")}
                    </div>
                  </div>
                  <div className="sbui-formlayout__content-container-horizontal">
                    <div className="sbui-input-container">
                      <input
                        name="confirm_password"
                        placeholder={i18next.t("confirmed_password")}
                        type="password"
                        className="sbui-input sbui-input--large"
                        defaultValue=""
                        {...register("confirmed")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between gap-4 mb-5 ">
            <div className="flex flex-col w-full">
              <span className="sbui-btn-container sbui-btn--w-full">
                <button
                  type="submit"
                  className="sbui-btn sbui-btn-primary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
                >
                  <span>{i18next.t("send")}</span>
                </button>
              </span>
            </div>
            <div className="flex flex-col w-full" />
          </div>
        </form>
      </div>
    </div>
  );
}
