import classNames from "classnames";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useStores } from "_common/hooks";

// redux

import RegisterForm from "./RegisterForm";

function RegisterPage({ onClose, setTypeOpen }) {
  const navigate = useNavigate();
  const {
    authStore: { banner_register_top, banner_register_footer },
  } = useStores();

  const [type, setType] = useState("phone");

  return (
    <div
      className="sbui-sidepanel-container register-page"
      style={{
        paddingTop: "50px",
      }}
    >
      <HeaderTop title={i18next.t("register")} />
      <div>
        <div className="sbui-sidepanel--medium auth-page">
          <div className="sbui-sidepanel">
            <div
              className="sbui-space-col sbui-space-y-6"
              style={{ height: "100%" }}
            >
              <div className="sbui-sidepanel-content auth-container">
                <div className="flex flex-col  h-full">
                  <div className="sbui-auth">
                    <div className="sbui-space-col sbui-space-y-8 sbui-auth-label">
                      <div className="tabs">
                        <div
                          className={classNames("tab", {
                            active: type === "phone",
                          })}
                          onClick={() => setType("phone")}
                        >
                          {i18next.t("register_with_phone_number")}
                        </div>
                        <div
                          className={classNames("tab", {
                            active: type === "email",
                          })}
                          onClick={() => setType("email")}
                        >
                          {i18next.t("register_with_email")}
                        </div>
                      </div>
                      <RegisterForm type={type} setTypeOpen={setTypeOpen} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
