import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { useStores } from "_common/hooks";
import { Link, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

import usdt from "assets/icons/usdt.svg";
import eth from "assets/icons/eth.svg";
import btc from "assets/icons/btc.svg";
import icon20 from "assets/img/icon/icon20.png";

export default function Recharge() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (
    <div className="mt-5">
      <HeaderTop
        title={i18next.t("withdraw")}
        rightIcon={
          <Link to="/board/history?type=withdraw">Chi tiết rút tiền</Link>
        }
      />
      <div id="withdraw" className=" mt-6">
        <div className="flex flex-col w-full gap-10 ">
          <div className="flex flex-1">
            <div className="card w-full h-full">
              <div className="list-coin-deposit">
                <Link to="/board/withdraw/submit?symbol=usdt" className="item">
                  <div className="left">
                    <img src={usdt} alt="" />
                    <span>USDT {i18next.t("withdraw")}</span>
                  </div>
                  <div className="right">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </Link>
                <Link to="/board/withdraw/submit?symbol=eth" className="item">
                  <div className="left">
                    <img src={eth} alt="" />
                    <span>ETH {i18next.t("withdraw")}</span>
                  </div>
                  <div className="right">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </Link>
                <Link to="/board/withdraw/submit?symbol=btc" className="item">
                  <div className="left">
                    <img src={btc} alt="" />
                    <span>BTC {i18next.t("withdraw")}</span>
                  </div>
                  <div className="right">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </Link>
                <Link
                  to="/board/withdraw/bank/submit?symbol=usd"
                  className="item"
                >
                  <div className="left">
                    <img src={icon20} alt="" />
                    <span>USD {i18next.t("withdraw")}</span>
                  </div>
                  <div className="right">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </Link>
                <Link
                  to="/board/withdraw/bank/submit?symbol=vnd"
                  className="item"
                >
                  <div className="left">
                    <img src={icon20} alt="" />
                    <span>VND {i18next.t("withdraw")}</span>
                  </div>
                  <div className="right">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </Link>
                <Link
                  to="/board/withdraw/bank/submit?symbol=cny"
                  className="item"
                >
                  <div className="left">
                    <img src={icon20} alt="" />
                    <span>CNY {i18next.t("withdraw")}</span>
                  </div>
                  <div className="right">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </Link>
                <Link
                  to="/board/withdraw/bank/submit?symbol=hkd"
                  className="item"
                >
                  <div className="left">
                    <img src={icon20} alt="" />
                    <span>HKD {i18next.t("withdraw")}</span>
                  </div>
                  <div className="right">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </Link>
                <Link
                  to="/board/withdraw/bank/submit?symbol=jpy"
                  className="item"
                >
                  <div className="left">
                    <img src={icon20} alt="" />
                    <span>JPY {i18next.t("withdraw")}</span>
                  </div>
                  <div className="right">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </Link>
                <Link
                  to="/board/withdraw/bank/submit?symbol=gbp"
                  className="item"
                >
                  <div className="left">
                    <img src={icon20} alt="" />
                    <span>GBP {i18next.t("withdraw")}</span>
                  </div>
                  <div className="right">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </Link>
                <Link
                  to="/board/withdraw/bank/submit?symbol=eur"
                  className="item"
                >
                  <div className="left">
                    <img src={icon20} alt="" />
                    <span>EUR {i18next.t("withdraw")}</span>
                  </div>
                  <div className="right">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
