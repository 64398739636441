import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { observer } from "mobx-react-lite";
import titleicon from "assets/img/titleicon.png";
import SelectBox from "_common/component/SelectBox";
import DesktopSideBar from "../SideBar/DesktopSideBar";

const optionsData = [
  {
    value: "vi",
    label: "Tiếng việt",
  },
  {
    value: "en",
    label: "English",
  },
];

const HeaderMobile = observer(() => {
  const {
    authStore: { language, setLanguage, theme_light, logo },
  } = useStores();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [open_menu, setOpenMenu] = useState(false);

  const handleChangeTheme = () => {
    const styles = document.getElementsByTagName("html")[0].style;
    styles.setProperty(`color-scheme`, "light");
    document.getElementsByTagName("html")[0].className = "tw-dark light";
  };

  useEffect(() => {
    if (theme_light) {
      handleChangeTheme();
    }
  }, [theme_light]);

  const handleChangeLanguage = (item) => {
    setLanguage(item?.value);
  };

  return (
    <div className="flex items-center justify-center w-full py-2 border-b bg-gray-900 border-gray-300 dark:border-gray-800">
      <img style={{ width: 100 }} src={logo} alt="/" className="menu-icon" />
    </div>
  );
});

export default HeaderMobile;
