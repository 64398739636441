import React, { useEffect, useState } from "react";

import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { NotificationManager } from "react-notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BankApi } from "states/api/bank";
import { optionBank } from "_common/constants/bank";

import { Portal } from "react-portal";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

const schema = yup
  .object({
    bank_owner: yup.string().required(i18next.t("verify_require")),
    bank_number: yup.string().required(i18next.t("verify_require")),
    bank_name: yup.string().required(i18next.t("verify_require")),
  })
  .required();

export default function AddBankForm() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();

  const [params] = useSearchParams();

  const bank_type = params?.get("bank_type");

  const { mutate, isLoading } = useMutation(
    (variables) => BankApi.create({ params: variables }),
    {
      onSuccess: (res) => {
        navigate(-1);
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSave = (values) => {
    if (isLoading) return;
    trigger();

    mutate({
      ...values,
      user_id: user?.id,
    });
  };

  return (
    <div className="container bank-page mt-10">
      <HeaderTop title={i18next.t("bank_card_link")} />
      {bank_type === "local" && (
        <div className="card-note mt-2 mb-5">
          {i18next.t("bank_card_link_msg")}
        </div>
      )}
      <div className="bg-white  px-4  py-8">
        <form
          className="sbui-space-row sbui-space-x-5"
          style={{ alignItems: "flex-start", width: "100%" }}
          onSubmit={handleSubmit(onSave)}
        >
          <div
            className="sbui-space-col sbui-space-y-4"
            style={{ alignItems: "flex-start", width: "100%" }}
          >
            <div className="flex flex-col w-full gap-4">
              <div>
                <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                  <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                    <div className="sbui-formlayout__label">
                      {i18next.t("bank_owner")}
                    </div>
                  </div>
                  <div className="sbui-formlayout__content-container-horizontal">
                    <div className="sbui-input-container">
                      <input
                        name="bank_account"
                        placeholder={i18next.t("bank_owner")}
                        type="text"
                        className="sbui-input sbui-input--medium"
                        {...register("bank_owner")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                  <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                    <div className="sbui-formlayout__label">
                      {i18next.t("bank_number")}
                    </div>
                  </div>
                  <div className="sbui-formlayout__content-container-horizontal">
                    <div className="sbui-input-container">
                      <input
                        name="bank_number"
                        placeholder={i18next.t("bank_number")}
                        type="text"
                        className="sbui-input sbui-input--medium"
                        {...register("bank_number")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                  <div className="sbui-formlayout__label">
                    {i18next.t("bank_name")}
                  </div>
                </div>
                <div className="sbui-formlayout__content-container-horizontal">
                  <div className="sbui-input-container">
                    <input
                      name="bank_name"
                      placeholder={i18next.t("bank_name")}
                      type="text"
                      className="sbui-input sbui-input--medium"
                      {...register("bank_name")}
                    />
                  </div>
                </div>
              </div>
              {/* {!user?.otp && (
                  <div>
                    <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                      <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                        <div className="sbui-formlayout__label">
                          {i18next.t("otp_digital")}
                        </div>
                      </div>
                      <div className="sbui-formlayout__content-container-horizontal">
                        <div className="sbui-input-container">
                          <input
                            name="bank_number"
                            placeholder={i18next.t("otp_digital")}
                            type="text"
                            className="sbui-input sbui-input--medium"
                            {...register("otp")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}
              {bank_type === "local" && (
                <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                  <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                    <div className="sbui-formlayout__label">
                      {i18next.t("bank_branch")}
                    </div>
                  </div>
                  <div className="sbui-formlayout__content-container-horizontal">
                    <div className="sbui-input-container">
                      <input
                        name="bank_address"
                        placeholder={i18next.t("bank_branch")}
                        type="text"
                        className="sbui-input sbui-input--medium"
                        {...register("bank_branch")}
                      />
                    </div>
                  </div>
                </div>
              )}

              {bank_type === "visa" && (
                <>
                  <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                    <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                      <div className="sbui-formlayout__label">
                        {i18next.t("bank_provide")}
                      </div>
                    </div>
                    <div className="sbui-formlayout__content-container-horizontal">
                      <div className="sbui-input-container">
                        <input
                          name="bank_provide"
                          placeholder={i18next.t("bank_provide_msg")}
                          type="text"
                          className="sbui-input sbui-input--medium"
                          {...register("bank_provide")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                    <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                      <div className="sbui-formlayout__label">
                        {i18next.t("open_an_account")}
                      </div>
                    </div>
                    <div className="sbui-formlayout__content-container-horizontal">
                      <div className="sbui-input-container">
                        <input
                          name="bank_address"
                          placeholder={i18next.t("bank_address_msg")}
                          type="text"
                          className="sbui-input sbui-input--medium"
                          {...register("bank_address")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                    <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                      <div className="sbui-formlayout__label">
                        {i18next.t("identify_id")}
                      </div>
                    </div>
                    <div className="sbui-formlayout__content-container-horizontal">
                      <div className="sbui-input-container">
                        <input
                          name="identify_id"
                          placeholder={i18next.t("identify_id_msg")}
                          type="text"
                          className="sbui-input sbui-input--medium"
                          {...register("identify_id")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                    <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                      <div className="sbui-formlayout__label">
                        {i18next.t("country_code")}
                      </div>
                    </div>
                    <div className="sbui-formlayout__content-container-horizontal">
                      <div className="sbui-input-container">
                        <input
                          name="bank_address"
                          placeholder={i18next.t("country_code_msg")}
                          type="text"
                          className="sbui-input sbui-input--medium"
                          {...register("country_code")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                    <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                      <div className="sbui-formlayout__label">
                        {i18next.t("Số liên lạc")}
                      </div>
                    </div>
                    <div className="sbui-formlayout__content-container-horizontal">
                      <div className="sbui-input-container">
                        <input
                          name="bank_phone"
                          placeholder={i18next.t("bank_phone_msg")}
                          type="text"
                          className="sbui-input sbui-input--medium"
                          {...register("bank_phone")}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {!isValid && isSubmitted && (
              <p className="text-sm text-red-500">{i18next.t("note_12")}</p>
            )}

            <div className="bank_footer">
              <button type="submit" disabled={!isValid} className="sbui-btn">
                <span>{i18next.t("open_an_account")}</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
